import { getMyNetworkingByKey } from '../../myNetworkingSelectors';
import { appendId } from '../../myNetworkingUtils';
import { isNotGroupMember } from '../chatgroups';
import {
  CHATGROUPINVITATIONS_SELECTOR_KEY,
  CHATGROUPINVITATIONS_SELECTOR_KEY_BY_ID
} from './chatgroupinvitationConstants';

export const getChatgroupinvitations = () => getMyNetworkingByKey(CHATGROUPINVITATIONS_SELECTOR_KEY) ?? [];

// It can happen, that the user can be a group member but at the same time have the group invitation.
// From the list og invitations we remove groups we are already a member of.
export const chatgroupInvitationsFiltered = () => getChatgroupinvitations().filter((el) => isNotGroupMember(el.chatgroup.id));

export const isInChatgroupinvitations = (groupIdStr) => {
  const groupId = parseInt(groupIdStr, 10);
  const chatgroupinvitations = getChatgroupinvitations();
  return chatgroupinvitations.some((el) => el.chatgroup.id === groupId);
};

export const getChatgroupinvitationsByGroupId = (id) => {
  const selectorKey = appendId(CHATGROUPINVITATIONS_SELECTOR_KEY_BY_ID, id);
  return getMyNetworkingByKey(selectorKey) ?? [];
};

export const userIsInvited = (groupIdStr, userIdStr) => {
  const groupId = parseInt(groupIdStr, 10);
  const userId = parseInt(userIdStr, 10);

  const chatgroupinvitations = getChatgroupinvitationsByGroupId(groupId);
  return chatgroupinvitations.some((el) => el.to_user.id === userId);
};

export const userIsNotInvited = (groupId, userId) => !userIsInvited(groupId, userId);

export const isChatgroupinvitationCreatedByChatgroupId = (groupIdStr) => {
  const groupId = parseInt(groupIdStr, 10);

  const chatgroupinvitatins = getChatgroupinvitations();
  return chatgroupinvitatins.some((el) => el.chatgroup.id === groupId);
};

export const isNotChatgroupinvitationCreatedByChatgroupId = (groupId) => !isChatgroupinvitationCreatedByChatgroupId(groupId);
