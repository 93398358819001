/* eslint-disable import/prefer-default-export */
import store from '../../../global-state/redux';
import storageAndCookie from '../../storage-and-cookies';

const { getState } = store;

export const getJwtToken = () => {
  const { instance } = getState().appState?.settings?.modules?.loginToJMTModule.settings ?? {};

  const instanceUser = `${instance}.user`;
  try {
    return JSON.parse(storageAndCookie.get(instanceUser)).accessToken;
  } catch {
    console.log('No access token was found under instance.user', {
      instance,
      instanceUser
    });
    return null;
  }
};
