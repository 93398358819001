import { lazy } from 'react';

export const Navbar = lazy(() => import('./Navbar'));
export const TopNavigation = lazy(() => import('./elements/TopNavigation'));
export const NavigationItem = lazy(() => import('./elements/NavigationItem'));
export const NavigationWrapper = lazy(() => import('./elements/NavigationWrapper'));
export const PageTitle = lazy(() => import('./elements/PageTitle'));
export const ProfileItems = lazy(() => import('./elements/ProfileItems'));
export const SidebarMenu = lazy(() => import('./elements/SidebarMenu'));
export const NavMenuItem = lazy(() => import('./elements/NavMenuItem'));

export const SimpleResponsiveHeader = lazy(() => import('./SimpleResponsiveHeader'));
