import storageAndCookie from '../../global-utils/storage-and-cookies';
import { selectConfig } from './selectors';
import { buildRequest, canBuildeRequest } from './utils';
import { SET_USER_PROFILE_DATA } from './constants';
import { dispatchVmEvents } from '../../global-utils';

export const setUserProfileData = (val) => ({
  type: SET_USER_PROFILE_DATA,
  payload: val
});

export const fetchUserComplete = (props) => (dispatch, getState) => {
  const { onSuccess } = props ?? {};
  const state = getState();
  const config = selectConfig(state) ?? {};
  const { instance } = config;

  const { accessToken } = JSON.parse(storageAndCookie.get(`${instance}.user`)) ?? {};

  const configNew = { ...config, accessToken };

  if (!canBuildeRequest(configNew)) {
    dispatch(
      setUserProfileData({
        apiStatus: 'error',
        error: { reason: configNew, message: 'Cannot parse URL' }
      })
    );
    return;
  }

  dispatch(setUserProfileData({ apiStatus: 'loading' }));
  fetch(buildRequest(configNew))
    .then((res) => res.json(res))
    .then((res) => res.filter((el) => el.name === 'UserComplete'))
    .then((res) => {
      dispatch(
        setUserProfileData({
          apiStatus: 'ready',
          isCompleted: res.length === 0
        })
      );
      dispatchVmEvents(onSuccess, { dataBankExtend: { res } });
    })
    .catch((error) => {
      dispatch(setUserProfileData({ apiStatus: 'error', error }));
    });
};
