import { Link as ReactLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useShallowEqualSelector from '../core/useShallowEqualSelector';
import {
  componentsPropType,
  stylesPropType,
  vmPropTypes
} from '../../global-prop-types';
import ComponentsArrRenderer from '../core/ComponentsArrRenderer';
import { mapData } from '../../global-utils/dataMapping';
import vmFunctions from '../../global-utils/vmFunctions';
import { setClientData } from '../../global-state/redux/actions';
import { isProtectedUrl } from '../componentsUtils';
import {
  useCheckCommunicableOnlyAccessUrl,
  useCheckMemberOnlyAccessUrl,
  useDispatchVmEventList,
  useIsUserAMember
} from '../../global-utils';
import { useAddonSettings } from '../../global-hooks';
import { isCommunicable } from '../../global-utils/vmFunctions/myNetworking/settings';
import { isInsideAppContainer } from '../../global-utils/vmFunctions/others';

const ClickHref = (props) => {
  const {
    styles,
    to, // React Router Link prop
    replace, // React Router Link prop
    disableWhen, // disable routing at breakpoints
    onClick = () => null, // DEPRICATED - please do not add new components wit this property
    vmOnClick, // We cannot mix vmTypes of props, otherwise admin gets crazy :)
    variant,
    disablePointerEvents, // adds a css class to <a/> tag to disable pointer events
    ...restProps
  } = props;
  const { currentAppUrl, currentBreakpoint } = useShallowEqualSelector(
    (state) => state.appState
  );
  const loggedInOnlySettings = useAddonSettings('loggedInOnly');
  const membershipOnlySettings = useAddonSettings('membershipOnly');
  const isUserAMember = useIsUserAMember();
  const communicableOnlySettings = useAddonSettings('communicableOnly');
  const checkMemberOnlyAccessUrl = useCheckMemberOnlyAccessUrl();
  const checkCommunicableOnlyAccessUrl = useCheckCommunicableOnlyAccessUrl();
  const allState = useShallowEqualSelector((state) => state);
  const isNotLink = disableWhen?.includes(currentBreakpoint) || false;
  const dispatch = useDispatch();
  const dispatchVmEventList = useDispatchVmEventList();
  // TODO: remove after refactorinh holdingPage
  const { event_id: eventId } = useSelector((state) => state?.appState?.core) ?? {};

  const handleOnClick = (e) => {
    dispatchVmEventList(vmOnClick, { vmFunctions, props });
    // !!! Check if works with ectrims and replace placeholders
    if (typeof onClick === 'function') {
      onClick(e);
    } else {
      // DEPRICATED: Please do not use onClick property anymore!
      const dataBank = { vmFunctions, props };
      // it's our array of mapping
      onClick.forEach((dataMap) => {
        const val = mapData(dataBank, dataMap);
        if (val?.func?.isReduxAction) {
          dispatch(val.func(...val.args));
        }
      });
    }

    // TODO: remove after refactoring holdingPage
    if (eventId === 585 && (isInsideAppContainer() || window.self !== window.top)) {
      return;
    }

    // ORDER MATTERS - checking for being logged in should happen befor chacking for membership.
    if (loggedInOnlySettings?.isActive && isProtectedUrl(to, allState)) {
      e.preventDefault();
      dispatch(setClientData(loggedInOnlySettings?.modalName, true)); // dispatch JmeOverLayer loggedIn (modalName from loggedInOnly addon)
      // IMPORTANT - this return is important so we do not trigger at the same time the modal for the membership only
      //   This makes sens only as long as membership is a sub-condition to beeing logged in.
      return;
    }

    // Check for members-only condition:
    if (
      membershipOnlySettings?.isActive
      && checkMemberOnlyAccessUrl(to)
      && !isUserAMember
    ) {
      e.preventDefault();
      dispatch(setClientData(membershipOnlySettings?.modalName, true));
    }

    // Check for communicable-only condition:
    if (
      communicableOnlySettings?.isActive
      && checkCommunicableOnlyAccessUrl(to)
      && isUserAMember
      && !isCommunicable()
    ) {
      e.preventDefault();
      dispatch(setClientData(communicableOnlySettings?.modalName, true));
    }
  };

  return (
    <ReactLink
      className={`clickHref-wrapper ${
        disablePointerEvents ? 'vmDisablePointerEvents' : ''
      }  ${variant || ''}`}
      to={isNotLink ? '#' : `${currentAppUrl}${to}`}
      replace={replace}
      style={styles?.wrapper}
      onClick={handleOnClick}
    >
      <ComponentsArrRenderer {...restProps} />
    </ReactLink>
  );
};

ClickHref.propTypes = {
  variant: PropTypes.string,
  styles: stylesPropType,
  components: componentsPropType,
  // React link props:
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  replace: PropTypes.bool,
  disableWhen: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  vmOnClick: PropTypes.array,
  disablePointerEvents: PropTypes.bool,
};

ClickHref.defaultProps = {
  variant: '',
  styles: { wrapper: {} },
  components: [],
  // React link props:
  to: '#',
  replace: false,
  disableWhen: [],
  disablePointerEvents: false
  // onClick: () => null
};

ClickHref.vmPropTypes = {
  variant: vmPropTypes.className,
  styles: { wrapper: vmPropTypes.styles },
  components: vmPropTypes.components,
  // React link props:
  to: vmPropTypes.string,
  replace: vmPropTypes.boolean,
  onClick: vmPropTypes.function,
  vmOnClick: vmPropTypes.array,
  disablePointerEvents: vmPropTypes.boolean
};

export default ClickHref;
