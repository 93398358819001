import store from '../../../global-state/redux';
import { MODULE_NAME } from './constants';
import { getLoginInfo } from '../../../global-utils/loginInfoUtils';
import { selectSettings } from '../../hooks';

const { getState } = store;

export const getManageConfig = () => selectSettings(MODULE_NAME)(getState());

export const getUserId = () => getLoginInfo(getState())?.manage_api_user?.id;

export const getAccessToken = () => getLoginInfo(getState())?.manage_api_user?.token;

export const getInstance = () => selectSettings(MODULE_NAME)(getState())?.instance;

export const getLanguage = () => selectSettings(MODULE_NAME)(getState())?.language;

export const getShort = () => selectSettings(MODULE_NAME)(getState())?.short;

export const getEventId = () => selectSettings(MODULE_NAME)(getState())?.eventId;

export const getApiBaseUrl = () => selectSettings(MODULE_NAME)(getState())?.apiBaseUrl;

export const getShowHcpPopUp = () => selectSettings(MODULE_NAME)(getState())?.showHcpPopup;
