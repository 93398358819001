import { dispatchVmEvents } from '../../../vmEvents';
import { coerceToObj } from '../myNetworkingUtils';
import { getAppId, getEventId } from '../../core';
import { myNetworkingApiCall } from '../myNetworkingApiUtils';
import { getAccessToken, getUserId } from '../../loginInfo/loginInfo';
import { FILESUPLOAD_TYPE_BANNER_IMAGE } from './settingsConstants';
import { isArr } from '../../vmArray';
import { getJwtToken } from '../../loginInfo';
import { apiGetSettings } from './settingsApi';
import storageAndCookie from '../../../storage-and-cookies';

const STORE_MASK_SETTINGS_USER_GET = 'myNetworking/settings/user/get';
const STORE_MASK_SETTINGS_USER_SET = 'myNetworking/settings/user/set';
const STORE_MASK_SETTINGS_USER_SYNC = 'myNetworking/settings/user/sync';
const MANAGE_JWT = 'manage_jwt';

const getPathname = (str) => `/v1/users/${str}.json`;

export const apiGetUserSettings = (props) => {
  const { skipDebounce } = coerceToObj(props);

  const body = {
    access_token: getAccessToken(),
    event_id: getEventId(),
    app_id: getAppId(),
    include_filesupload_items: 1,
    include_tags: 1,
    include_filesupload_items_filesupload_type: FILESUPLOAD_TYPE_BANNER_IMAGE,
    include_partner_membership: 1,
    include_manage_admin_user_roles: 1,
    check_visibility_with_user_id: getUserId(),
    check_visibility_with_user_access_token: getAccessToken()
  };

  return myNetworkingApiCall({
    method: 'GET',
    storeMask: STORE_MASK_SETTINGS_USER_GET,
    pathname: getPathname(getUserId()),
    body,
    skipDebounce
  });
};

const assembleFilesuploadItems = (filesuploadItem) => {
  const { id } = filesuploadItem;
  const filesuploadItems = [
    { filesupload_id: id, filesupload_type: FILESUPLOAD_TYPE_BANNER_IMAGE }
  ];
  return JSON.stringify(filesuploadItems);
};

export const apiSetUserSettings = (props) => {
  const { skipDebounce, tagIds, filesuploadItem } = coerceToObj(props);

  const body = {
    event_id: getEventId(),
    access_token: getAccessToken(),
    include_filesupload_items: 1,
    include_tags: 1,
    source: 'vm'
  };

  if (isArr(tagIds)) body.tag_ids = tagIds.join();
  if (filesuploadItem) {
    body.filesupload_items = assembleFilesuploadItems(filesuploadItem);
  }

  return myNetworkingApiCall({
    method: 'PUT',
    storeMask: STORE_MASK_SETTINGS_USER_SET,
    pathname: getPathname(getUserId()),
    body,
    skipDebounce
  });
};

// check VSX-129 for Chen's explanation.
export const syncJmtSettings = async (props) => {
  const {
    skipDebounce,
    refreshRate,
    onErrorVmEventList,
    onSuccessVmEventList
  } = coerceToObj(props);

  const body = {
    access_token: getAccessToken(),
    event_id: getEventId(),
    jwt_token: getJwtToken(),
    source: MANAGE_JWT
  };

  return myNetworkingApiCall({
    method: 'PUT',
    storeMask: STORE_MASK_SETTINGS_USER_SYNC,
    pathname: getPathname(getUserId()),
    body,
    skipDebounce,
    refreshRate
  })
    .then((res) => {
      // TODO: hack done for ERS meeting
      const localSavedSession = storageAndCookie.get('access_token_all', true);
      const obj = JSON.parse(localSavedSession);
      const objNew = {
        ...obj,
        user: {
          ...obj.user,
          profile_image_url: res.data.profile_pic_url
        }
      };
      storageAndCookie.set(
        'access_token_all',
        JSON.stringify(objNew),
        new Date(obj.expires_at),
        true
      );
      return res;
    })
    .then((res) => {
      if (res.skipDebounce) return res;
      return apiGetSettings({ onSuccessVmEventList, skipDebounce: true });
    })
    .catch((error) => {
      dispatchVmEvents(onErrorVmEventList, { dataBankProps: { error } });
      console.error(error);
    });
};
