import { z } from 'zod';

export const manageConfigSchema = z.object({
  eventId: z.preprocess((val) => {
    const n = Number(val);
    if (Number.isNaN(n)) return null;
    return n;
  }, z.number().nullable()),

  short: z.preprocess((val) => {
    if (typeof val === 'string') return val;
    return null;
  }, z.string().nullable()),

  instance: z.preprocess((val) => {
    if (typeof val === 'string') return val;
    return null;
  }, z.string().nullable()),

  apiBaseUrl: z.preprocess((val) => {
    if (typeof val === 'string') return val;
    return null;
  }, z.string().nullable()),

  language: z.preprocess((val) => {
    if (typeof val === 'string') return val;
    return null;
  }, z.string().nullable())
});
