import storageAndCookie from '../../../global-utils/storage-and-cookies';

export const apiExtendUserDetails = (manageConfig = {}) => {
  const { instance, eventId } = manageConfig;
  const { accessToken } = JSON.parse(storageAndCookie.get(`${instance}.user`)) ?? {};

  // if module config misses crucial params do not make API call
  // but also do not reject promiss, coz we can still continue with tracking
  // without user being logged-in
  if (!(instance && eventId && accessToken)) return {};

  const url = `https://${instance}-api.m-anage.com/api/Core/User?eventid=${eventId}`;

  return fetch(url, {
    headers: { Authorization: `Bearer ${accessToken}` }
  }).then((resp) => {
    if (!resp.ok) {
      return Promise.reject(
        new Error('data-insight: Cannot fetch extended user data', {
          cause: resp
        })
      );
    }
    return resp.json();
  });
};
