import { getEventId } from '../../../core';
import { getAccessToken, getUserId } from '../../../loginInfo/loginInfo';
import { myNetworkingApiCall } from '../../myNetworkingApiUtils';
import { appendId, coerceToObj } from '../../myNetworkingUtils';
import store from '../../../../../global-state/redux';
import { setMyNetworking } from '../../../../../global-state/redux/actions';
import { COMMUNITYUSERS_SELECTOR_KEY } from './communityUserConstants';
import { getCommunityPartnerByPartnerId } from './communityUserSelectors';
import { FILESUPLOAD_TYPE_BANNER_IMAGE } from '../../settings/settingsConstants';

const STORE_MASK_COMMUNITYUSER_GET = 'myNetworking/chats/connections/get';
const STORE_MASK_COMMUNITYUSER_DELETE = 'myNetworking/chats/connections/delete';

const PATHNAME = '/v1/chats.json';
const getPathname = (id) => `/v1/chats/${id}.json`;

const { dispatch } = store;

export const apiGetCommunityUsers = (props) => {
  const { onSuccessVmEventList, onErrorVmEventList, skipDebounce } = coerceToObj(props);

  const body = {
    user_id: getUserId(),
    access_token: getAccessToken(),
    event_id: getEventId(),
    include_blocked: 0,
    include_partner_membership: 1,
    include_partner_filesupload_items: 1,
    include_partner_filesupload_items_filesupload_type: FILESUPLOAD_TYPE_BANNER_IMAGE,
    include_partner_tags: 1,
    include_manage_admin_user_roles: 1
  };

  return myNetworkingApiCall({
    method: 'GET',
    storeMask: STORE_MASK_COMMUNITYUSER_GET,
    pathname: PATHNAME,
    body,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  }).then((res) => {
    if (!res.isDebounced) {
      // save to relevant myNetworking location in redux:
      dispatch(setMyNetworking(COMMUNITYUSERS_SELECTOR_KEY, res.data));
    }

    return res;
  });
};

export const apiGetCommunityUsersByGroupId = (props) => {
  const {
    groupId,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  } = coerceToObj(props);

  const body = {
    user_id: getUserId(),
    access_token: getAccessToken(),
    event_id: getEventId(),
    include_chatgroupinvitation: 1,
    chatgroup_id: groupId
  };

  return myNetworkingApiCall({
    method: 'GET',
    storeMask: appendId(STORE_MASK_COMMUNITYUSER_GET, groupId),
    pathname: PATHNAME,
    body,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  }).then((res) => {
    if (!res.isDebounced) {
      // save to relevant myNetworking location in redux:
      dispatch(setMyNetworking(appendId(COMMUNITYUSERS_SELECTOR_KEY, groupId), res.data));
    }

    return res;
  });
};

export const apiCommunityUserDelete = (props) => {
  const {
    partnerId: partnerIdStr,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  } = coerceToObj(props);

  const partnerId = parseInt(partnerIdStr, 10);

  // find the invitation:
  const { id: chatId } = getCommunityPartnerByPartnerId(partnerId) ?? {};

  if (!partnerId || !chatId) return;

  const body = {
    per_page: 999, // get all
    access_token: getAccessToken(),
    id: chatId,
    user_id: getUserId(),
    myself_blocked: 0,
    myself_deleted: 1
  };

  myNetworkingApiCall({
    method: 'PUT',
    storeMask: appendId(STORE_MASK_COMMUNITYUSER_DELETE, chatId),
    pathname: getPathname(chatId),
    body,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  }).then((res) => {
    if (res.isDebounced) return;

    apiGetCommunityUsers({ skipDebounce: true });
  });
};
