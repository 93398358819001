import { isArr, isStr } from '../../global-utils';

export const checkReservedUrl = (url, reservedUrls) => {
  if (!isStr(url) || !isArr(reservedUrls)) return false;

  return reservedUrls.some((el) => new RegExp(el).test(url));
};

export const canBuildeRequest = (config) => {
  const { accessToken, shortName, apiHref } = config;

  return accessToken && shortName && URL.canParse(apiHref);
};

export const buildRequest = (config) => {
  const {
    accessToken, shortName, role, context, apiHref, WithCQCheck
  } = config;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify({
      context, shortName, role, WithCQCheck
    })
  };

  const url = new URL(apiHref);
  url.pathname = '/v2/Core/CheckPermissionsForRole';
  return new Request(url, options);
};
